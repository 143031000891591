import React from "react";

const Choice = () => {
  return (
    <div className="choice-wrap ">
      <div className="container">
        <div className="title">
          <h1>
            We Are The Best <span>Choice For Students Who are Going To Achieve</span>
          </h1>
        </div>
        <p>
          New Students Academy is one of the best institutes in Marathahalli
          providing training for a variety of programming languages, software
          technologies, and foreign language courses. The training is handled by
          industry experts who are proficient in their field and have a maximum
          working experience of more than 10 years. New Students Academy also
          provides placement support for students which students can utilize to
          get placed in the top-tier companies. Enroll in New Students Academy and
          realize your dream of working for top organizations.
        </p>
        <div className="readmore">
          <a href="contact">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default Choice;
