import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Gate = () => {
  return (
    <>
      <Header />

      <div className="container mt-5">
        <div className="card mb-4">
          <div className="card-body">
            <h2 className="card-title text-center">GATE-2019 and 2020</h2>
            <h5 className="card-subtitle mb-2 text-muted text-center">
              (Regular and Weekend Batches)
            </h5>
            <p className="card-text text-center">
              New Batches From 06-11-2018, & Every 15 Days New Batch Starts at
              Vijayawada and Guntur Centers
            </p>
            <h4 className="mt-4">
              NSA Offers RACE Programme For Sure Rank in GATE-2019 & 20
            </h4>
            <p className="font-italic">
              (Rapid Analysis & Calculations in Engineering)
            </p>
            <ul className="list-group list-group-flush mb-4">
              <li className="list-group-item">
                Concepts Strengthening Programme
              </li>
              <li className="list-group-item">
                Fundamentals & Basics Injection
              </li>
              <li className="list-group-item">Speed, Accuracy & Perfection</li>
              <li className="list-group-item">
                Detailed, Extensive Engineering Practice
              </li>
              <li className="list-group-item">Memory Retention Programme</li>
              <li className="list-group-item">
                Online & Offline Practice Test Sessions
              </li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h3 className="card-title text-center">
              Fee Structure for GATE Coaching
            </h3>
            <ul className="list-group list-group-flush mb-4">
              <li className="list-group-item">
                Fee Concession for Class Toppers.
              </li>
              <li className="list-group-item">
                Special Fee Concession for NSA old students who got ECET
                Coaching.
              </li>
              <li className="list-group-item">
                Extra Fee Concession for reservation & poor Students.
              </li>
              <li className="list-group-item">
                This fee is valid up to the 2020 GATE Exam.
              </li>
            </ul>
            <p className="card-text text-center font-weight-bold">
              Total fee - Rs. 30,000/-
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body text-center">
                    <p className="card-text">Class I: Rs. 10,000/-</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body text-center">
                    <p className="card-text">Class II: Rs. 15,000/-</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-3">
                  <div className="card-body text-center">
                    <p className="card-text">Class III: Rs. 18,000/-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card mb-3">
                  <div className="card-body text-center">
                    <p className="card-text">Above 85%: Rs. 20,000/-</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-3">
                  <div className="card-body text-center">
                    <p className="card-text">Above 75%: Rs. 25,000/-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gate;
