import React, { useState, useEffect } from "react";
import axios from "axios";
// import ROOT_URL from "../../Utilities/apiconfig";

const Gallery = () => {
  // const [galleryData, setGalleryData] = useState([]);

  // useEffect(() => {
  //   // Fetch data from backend
  //   axios.get(`${ROOT_URL}/getgallery`)

  //     .then(response => {
  //       // Assuming the response data is an array of objects with a field 'galleryimage'
  //       setGalleryData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching gallery data:', error);
  //     });
  // }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="gallery-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="gallery_box">
              <div className="gallery_left">
                <div className="title">
                  <h1>Our Victory Gallery</h1>
                </div>
                <p>
                  Join us as we embark on a transformative learning experience
                  together!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G3.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G3.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G2.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G2.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G4.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G4.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G5.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G5.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G6.jpeg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G6.jpeg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G7.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G7.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G8.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G8.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G9.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G9.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G10.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G10.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G11.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G11.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G15.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G15.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G17.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G17.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G18.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G18.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G19.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G19.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="galleryImg">
                  <img
                    src="/assets/images/G21.jpg"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="portfolio-overley">
                    <div className="content">
                      <a
                        href="assets/images/G21.jpg"
                        className="fancybox image-link"
                        data-fancybox="images"
                        title="Image Caption Here"
                      >
                        <i className="fas fa-search-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
