import React from 'react'
import Header from '../Header/Header'
import About from './About'
import Gallery from '../Gallery/Gallery'
import Testimonials from '../Testimonials/Testimonials'
import Enroll from '../Enroll/Enroll'
import Teacher from '../Teacher/Teacher'
import Newsletter from '../Newsletter/Newsletter'
import Footer from '../Footer/Footer'

const Aboutus = () => {
  return (
<>
<Header/>
<div class="innerHeading-wrap">
  <div class="container">
    <h1>About Us</h1>
  </div>
</div>
<About/>
{/* <Gallery/> */}
{/* <Testimonials/> */}
{/* <Enroll/> */}
{/* <Teacher/> */}
<Footer/>
</>
  )
}

export default Aboutus