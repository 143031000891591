import React from "react";

const Enroll = () => {
  return (
    <div className="choice-wrap enroll-wrap">
      <div className="container">
        <div className="title">
          <h1>Call To Enroll</h1>
        </div>
        <p>
        New Students Academy is one of the best institutes in Vijayawada, providing exceptional coaching for ECET and GATE exams. The coaching is delivered by industry experts who are highly proficient in their field with over 10 years of experience. New Students Academy also offers comprehensive College placement support, helping students secure positions in top University. Enroll in New Students Academy and take a significant step towards achieving your dream of excelling in ECET and GATE exams, and securing a successful career in prestigious organizations.
        </p>
        <div className="phonewrp">
          <img src="assets/images/phone_icon.png" alt="" />
          <a href="tel:9848128173">9848128173</a>
        </div>
      </div>
    </div>
  );
};

export default Enroll;
