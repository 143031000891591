import React from "react";

const Programs = () => {
  return (
    <div className="blog-wrap flight-wrap ">
      <div className="container">
        <div className="title">
          <h1> Our Programs </h1>
        </div>
        <ul className="row unorderList">
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program2.jpg" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">ECET / PGECET </a>
                </h3>
                <p>
                ECET preparation courses equip students with the knowledge and skills necessary to excel in the Engineering Common Entrance Test. These courses cover a wide range of topics, including mathematics, physics, chemistry, and engineering subjects. Students learn problem-solving techniques, time management strategies, and exam-taking tips tailored to the ECET format. With experienced instructors and comprehensive study materials, these courses provide a thorough understanding of the exam content, ensuring students are well-prepared to achieve top scores and secure admission into their desired engineering programs.
                </p>
              </div>
            </div>
          </li>
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program1.png" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">Spoken English Course</a>
                </h3>
                <p>
                  It is a short-term course for the youth to help improve their
                  English communication and soft skills. It has been designed in
                  a way to help learners gain the confidence to speak English
                  fluently.
                </p>
              </div>
            </div>
          </li>
          <li className="col-lg-4">
            <div className="blog_box">
              <div className="blogImg">
                <img src="assets/images/program3.jpg" alt=""  style={{ objectFit: "cover" }}/>
              </div>
              <div className="path_box">
                <h3>
                  <a href="#">Gate & Civil Services</a>
                </h3>
                <p>
                Civil services exams are competitive examinations held by various countries to recruit candidates for civil services positions in government departments. These exams are designed to assess the knowledge, skills, and aptitude of candidates to serve in administrative roles.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Programs;
