import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { logout } from "../../Redux/authactions";
import Logo from "../../Dashboard/Images/logo.png";

const Header = () => {
  // const user = useSelector((state) => state.auth.user);
  // const userRole = user ? user.role : null;
  // const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };


  const handleLogout = () => {
    // Dispatch the logout action
    // dispatch(logout());
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;

  return (
    <div className="header-wrap">
      <div className="container-fluid">
        <div className="row">
          {windowWidth > 768 && ( // Render address section only if window width is greater than 768px (tablet view)
            <div
              className="col-lg-12 address-section"
              style={{
                display: "flex",
                backgroundColor: "green",
                height: "40px",
                marginTop: "-10px",
              }}
            >
              {/* Branch 1 Address */}
              <marquee>
                <div className="col-lg-8" style={{ marginLeft: "-50px" }}>
                  <div className="header_info">
                    <ul className="footer-adress">
                      <li className="footer_address">
                        {" "}
                        <i className="fas fa-map-marker-alt"></i>{" "}
                        <span>
                          {" "}
                          <a
                            href="https://www.google.com/maps/place/NSA+Academy/@16.502888,80.623294,15z/data=!4m10!1m2!2m1!1snew+students+academy+,+vijayawada!3m6!1s0x3a35faafa2015329:0xfc88a8b65d9147e6!8m2!3d16.502888!4d80.6413184!15sCiFuZXcgc3R1ZGVudHMgYWNhZGVteSAsIHZpamF5YXdhZGFaISIfbmV3IHN0dWRlbnRzIGFjYWRlbXkgdmlqYXlhd2FkYZIBD2NvYWNoaW5nX2NlbnRlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOM2FrMVBSM0JuUlJBQuABAA!16s%2Fg%2F1q62kbttj?entry=ttu"
                            style={{ fontSize: "12px", color: "white" }}
                          >
                            {" "}
                            <span
                              style={{ fontWeight: "bold", color: "white" }}
                            >
                              {/* Branch : 1 */} New Students Academy{" "}
                              <span> 1994</span>
                            </span>{" "}
                            - Malabar gold,Labbipeta Mg road,behind hotel Hain
                            M.G.Road, Labbipet, Vijayawada.{" "}
                            <span> || Industrial Training also available</span>{" "}
                            <span>
                              {" "}
                              || Online and Offline Classes also available
                            </span>
                          </a>
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </marquee>

              {/* <div className="col-lg-4">
                <div className="header_info">
                  <ul className="footer-adress">
                    <li className="footer_address">
                      {" "}
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      <span>
                        {" "}
                        <a
                          href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                          style={{ fontSize: "12px", color: "white" }}
                        >
                          {" "}
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            Branch : 2
                          </span>{" "}
                          - Beside Kala Mandir, Marathahalli Bridge Signal
                          Bengaluru, Karnataka 560037{" "}
                        </a>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          )}

          <div className="col-lg-3 col-md-12 navbar-light">
            <div className="logo">
              {" "}
              <a href="/">
                <img
                  alt=""
                  className="logo-default"
                  src={Logo}
                  style={{ objectFit: "cover" }}
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleDropdownToggle}
              aria-controls="navbarSupportedContent"
              aria-expanded={isDropdownOpen}
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="navigation-wrap" id="filters">
              <nav className="navbar navbar-expand-lg navbar-light">
                {" "}
                <a className="navbar-brand" href="#">
                  Menu
                </a>
                <div
                  className={`collapse navbar-collapse ${isDropdownOpen ? "show" : ""}`}
                  id="navbarSupportedContent"
                >
                  <button
                    className="close-toggler"
                    type="button"
                    onClick={handleDropdownToggle}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isDropdownOpen}
                    aria-label="Toggle navigation"
                  >
                    <span>
                      <i className="fas fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </button>

                  <ul
                    className="navbar-nav mr-auto"
                    style={{ marginLeft: "50px" }}
                  >
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="/">
                        {isMobile && <i className="fas fa-home"></i>}
                        Home <span className="sr-only">(current)</span>
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/aboutus">
                        {isMobile && <i className="fas fa-info-circle"></i>}{" "}
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/ecet">
                        {isMobile && <i className="fas fa-graduation-cap"></i>}{" "}
                        ECET / PGECET
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/gate">
                        {isMobile && <i className="fas fa-graduation-cap"></i>}{" "}
                        GATE
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        {isMobile && (
                          <i className="fas fa-chalkboard-teacher"></i>
                        )}
                        Trainers
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="contact">
                        {isMobile && <i className="fas fa-envelope"></i>}Contact
                        Us
                      </a>
                    </li>
                  </ul>
                  {isMobile && (
                    <div className="col-lg-12 address-section">
                      {/* Branch 1 Address */}
                      <div className="col-lg-8">
                        <h3>Contact Info</h3>
                        <div className="header_info">
                          <ul className="footer-adress">
                            <li className="footer_address">
                              {" "}
                              <i className="fas fa-map-marker-alt"></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://www.google.com/maps/place/NSA+Academy/@16.502888,80.623294,15z/data=!4m10!1m2!2m1!1snew+students+academy+,+vijayawada!3m6!1s0x3a35faafa2015329:0xfc88a8b65d9147e6!8m2!3d16.502888!4d80.6413184!15sCiFuZXcgc3R1ZGVudHMgYWNhZGVteSAsIHZpamF5YXdhZGFaISIfbmV3IHN0dWRlbnRzIGFjYWRlbXkgdmlqYXlhd2FkYZIBD2NvYWNoaW5nX2NlbnRlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOM2FrMVBSM0JuUlJBQuABAA!16s%2Fg%2F1q62kbttj?entry=ttu"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Branch : 1 - <br />
                                  </span>{" "}
                                  {/* malabar gold,labbipeta Mg road,behind hotel
                                  hain M.G.Road, Labbipet, Vijayawada. */}
                                  Malabar Gold,
                                  <br /> Labbipeta Mg Road,
                                  <br /> Behind hotel Hain M.G.Road,
                                  <br /> Labbipet - 520003, <br /> Vijayawada.{" "}
                                </a>
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <div className="col-lg-8">
                        <div className="header_info">
                          <ul className="footer-adress">
                            <li
                              className="footer_address"
                              style={{ marginLeft: "-35px" }}
                            >
                              {" "}
                              <i className="fas fa-map-marker-alt"></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://maps.app.goo.gl/BHGgvTgbp4BMcKweA"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Branch : 2 - <br />
                                  </span>{" "}
                                  Beside Kala Mandir,
                                  <br /> Marathahalli - 560037 <br /> Bengaluru.{" "}
                                </a>
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      <div className="col-lg-8">
                        <h3>Contact Details</h3>
                        <div className="header_info">
                          <ul className="footer-adress">
                            <li
                              className="footer_address"
                              style={{ marginLeft: "65px" }}
                            >
                              {" "}
                              <i className="fas fa-phone-alt"></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://www.google.com/maps/place/NSA+Academy/@16.502888,80.623294,15z/data=!4m10!1m2!2m1!1snew+students+academy+,+vijayawada!3m6!1s0x3a35faafa2015329:0xfc88a8b65d9147e6!8m2!3d16.502888!4d80.6413184!15sCiFuZXcgc3R1ZGVudHMgYWNhZGVteSAsIHZpamF5YXdhZGFaISIfbmV3IHN0dWRlbnRzIGFjYWRlbXkgdmlqYXlhd2FkYZIBD2NvYWNoaW5nX2NlbnRlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOM2FrMVBSM0JuUlJBQuABAA!16s%2Fg%2F1q62kbttj?entry=ttu"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Tel -
                                  </span>{" "}
                                  98481 28173 / 7780444492{" "}
                                </a>
                              </span>{" "}
                            </li>
                            <li
                              className="footer_address"
                              style={{ marginLeft: "65px" }}
                            >
                              {" "}
                              <i
                                className="fas fa-envelope"
                                aria-hidden="true"
                              ></i>{" "}
                              <span>
                                {" "}
                                <a
                                  href="https://www.google.com/maps/place/NSA+Academy/@16.502888,80.623294,15z/data=!4m10!1m2!2m1!1snew+students+academy+,+vijayawada!3m6!1s0x3a35faafa2015329:0xfc88a8b65d9147e6!8m2!3d16.502888!4d80.6413184!15sCiFuZXcgc3R1ZGVudHMgYWNhZGVteSAsIHZpamF5YXdhZGFaISIfbmV3IHN0dWRlbnRzIGFjYWRlbXkgdmlqYXlhd2FkYZIBD2NvYWNoaW5nX2NlbnRlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOM2FrMVBSM0JuUlJBQuABAA!16s%2Fg%2F1q62kbttj?entry=ttu"
                                  style={{ fontSize: "12px", color: "white" }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    Email -
                                  </span>{" "}
                                  newkoushik@gmail.com{" "}
                                </a>
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="header_info">
              <div className="search">
                <a href="#">
                  <i className="fas fa-search"></i>
                </a>
              </div>
              <div className="loginwrp">
                {/* {userRole === "user" ? ( */}
                {/* <>
                    <div id="profileSection">
                      <img
                        src="https://i.pinimg.com/736x/44/4b/d6/444bd6f7040c5ead15609750140c3713.jpg"
                        className="imageStyle"
                        onClick={handleDropdownToggle}
                      />
                      {isDropdownOpen && (
                        <div className="flex flex-column dropDownsection">
                          <ul className="flex flex-column gap-4 unOrderedList">
                            <li>
                              <a href="">
                                <i className="fas fa-user"></i> Profile
                              </a>
                            </li>
                            <li>
                              <a href="/userbookings">
                                <i className="fas fa-book"></i> Bookings
                              </a>
                            </li>
                            <li>
                              <a href="#" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </> */}
                {/* ) : ( */}
                <a href="tel:9848128173">98481 28173</a>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
