import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Ecet = () => {
  return (
    <>
      <Header />
      <div className="gallery-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="gallery_box">
                {/* <div className="gallery_left">
                  <div className="title">
                    <h1>Our Victory Gallery</h1>
                  </div>
                  <p>
                    Join us as we embark on a transformative learning experience
                    together!
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="galleryImg">
                    <img
                      src="/assets/images/G1.jpg"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a
                          href="assets/images/G1.jpg"
                          className="fancybox image-link"
                          data-fancybox="images"
                          title="Image Caption Here"
                        >
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="galleryImg">
                    <img
                      src="/assets/images/G12.jpg"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a
                          href="assets/images/G12.jpg"
                          className="fancybox image-link"
                          data-fancybox="images"
                          title="Image Caption Here"
                        >
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="galleryImg">
                    <img
                      src="/assets/images/G13.jpg"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a
                          href="assets/images/G13.jpg"
                          className="fancybox image-link"
                          data-fancybox="images"
                          title="Image Caption Here"
                        >
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="galleryImg">
                    <img
                      src="/assets/images/G14.jpg"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a
                          href="assets/images/G14.jpg"
                          className="fancybox image-link"
                          data-fancybox="images"
                          title="Image Caption Here"
                        >
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="galleryImg">
                    <img
                      src="/assets/images/G20.jpg"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="portfolio-overley">
                      <div className="content">
                        <a
                          href="assets/images/G20.jpg"
                          className="fancybox image-link"
                          data-fancybox="images"
                          title="Image Caption Here"
                        >
                          <i className="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ecet;
