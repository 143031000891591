import React from "react";

const About = () => {
  return (
    <div className="about-wrap  " id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="aboutImg">
              <img
                src="assets/images/aboutImg_1.jpg"
                alt="about"
                style={{ borderRadius: "32px", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about_box">
              <div className="title">
                <h1>
                  Online / Offline Learing <span>Platform</span>
                </h1>
              </div>
              <p>
                Welcome to New Students Academy, where learning transcends
                boundaries and brilliance finds its true potential. Our platform
                is designed to ignite curiosity, foster creativity, and empower
                learners of all ages.
              </p>
              <ul className="edu_list">
                <li>
                  <div className="learing-wrp">
                    <div className="edu_icon">
                      <img
                        src="assets/images/education1.png"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className="learn_info">
                      <h3>All india IAS First Ranker</h3>
                      <p>
                        R.MUTYALA RAJU, Is our old Student
                        Receiving Cash Award by NSA Director
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="learing-wrp">
                    <div className="edu_icon">
                      <img
                        src="assets/images/class.png"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className="learn_info">
                      <h3>Honors classes</h3>
                      <p>Experience Academic Excellence with Honors Program</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="learing-wrp">
                    <div className="edu_icon">
                      <img
                        src="assets/images/academy.png"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className="learn_info">
                      <h3>Traditional academies</h3>
                      <p>Embrace Timeless Learning at Traditional Academies.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
